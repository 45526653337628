import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './Home.module.css'
import orderBy from 'lodash/orderBy'
import Header from './Header'
import Spinner from './Spinner'
import add from '../images/add.svg'
import { Button, Input, FormGroup, Label } from 'reactstrap'
import Dialog from './Dialog'
import logo from '../images/wistor-mark-color.svg'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import { fetchProjects, createProject } from '../redux/projects'

class Home extends Component {
  state = { values: {} }

  componentDidMount() {
    /** @type {LoginState} */
    const loginState = this.props.loginState
    if (loginState.isLoggedIn && loginState.user.role !== 'superadmin' && loginState.user.project) {
      return this.props.history.replace(
        `/projects/${loginState.user.project.path}`
      )
    }
    this.props.fetchProjects((error) => {
      if (error.response.status === 401) {
        this.props.history.push('/login', 'projects')
      }
    })
  }

  onDialogOkClick = () => {
    this.props.createProject(this.state.values, (error) => {
      if (error.response.status === 401) {
        this.props.history.push('/login', 'projects')
      }
    })
    this.setState({ dialogVisible: false })
  }

  render() {
    //   console.log("render HOME ",this.props);
    if (this.props.projects.loading) return <Spinner />
    if (!this.props.projects.loaded) return null

    /** @type {LoginState} */
    const loginState = this.props.loginState

    let projects = this.props.projects.items
    // projects = orderBy(projects, ['createdAt'], ['asc']);
   // console.log(projects, role)
    projects = orderBy(projects, ['title'], ['asc'])
    return (
      <div>
        <Helmet>
          <title>Wistor</title>
          <link rel="icon" type="image/png" href="/favicon.png" sizes="16x16" />
        </Helmet>
        <Header />
        <div className={styles.mainContainer}>
          <div className={styles.main}>
            <div className={styles.content}>
              <img
                src={logo}
                alt="logo"
                style={{ maxHeight: '96px', margin: '0 12px 12px' }}
              />
              <div className={styles.title}>Welcome to Wistor</div>
              <p className={styles.paragr}>
                  Data-centric working made easy
              </p>
              <div className={styles.subTitle}>Select a project:</div>
              <div className={styles.projects}>
                {projects.map((project) => {
                  return (
                    <div
                      className={styles.project}
                      key={project.id}
                      onClick={() =>
                        this.props.history.push(`/projects/${project.path}`)
                      }
                    >
                      <div>{project.title}</div>
                    </div>
                  )
                })}
                {loginState.user?.role === 'superadmin' && (
                  <div
                    className={styles.project}
                    onClick={() => this.setState({ dialogVisible: true })}
                  >
                    <div>
                      <img src={add} alt="add" style={{ width: '24px' }} />
                      <span
                        style={{ verticalAlign: 'middle', paddingLeft: '12px' }}
                      >
                        Create a new project...
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>

        {this.state.dialogVisible && (
          <Dialog>
            <FormGroup>
              <Label>Title</Label>
              <Input
                name="title"
                className="dark-form-control"
                value={this.state.values.title || ''}
                type="text"
                onChange={(e) =>
                  this.setState({
                    values: { ...this.state.values, title: e.target.value },
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Path</Label>
              <Input
                name="path"
                className="dark-form-control"
                value={this.state.values.path || ''}
                type="text"
                onChange={(e) =>
                  this.setState({
                    values: { ...this.state.values, path: e.target.value },
                  })
                }
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }}>
              <Button
                color="primary"
                style={{ minWidth: '120px', margin: '12px' }}
                onClick={() => this.setState({ dialogVisible: false })}
                aria-label="Cancel"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                style={{ minWidth: '120px', margin: '12px' }}
                onClick={this.onDialogOkClick}
                aria-label="OK"
              >
                OK
              </Button>
            </div>
          </Dialog>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  loginState: state.login,
})

export default connect(mapStateToProps, { fetchProjects, createProject })(Home)
