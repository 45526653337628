import { lazy } from 'react';

import PublishUtils from './../../helpers/PublishUtils'

export default class TableDescriptor {
    static getDescription() {
        return {
          name: 'Table',
          status:"normal",
          category:"Default",
          component: lazy(() =>  import('./Table')), 
          label: 'Table widget',
          variables: [
            {
              name: 'query',
              type: 'yasgui',

              description:"extra documentation",
              defaultQuery:"PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n\r\n\rselect distinct ?label ?labelURI ?comment\n\rwhere\n\r{\n\r    graph ?g\n\r  {\n\r    ?labelURI a/rdfs:subClassOf* <{{cls}}>. ?labelURI rdfs:label ?label.\n\r      optional { ?labelURI rdfs:comment ?comment}\n\r     \n\r  }\n\r\n\r}\n\r        limit 100",
              label:
                'The results of this query are displayed in the table in the same order as they are returned from the triplestore. ' +
                'Note that results of query-variable-names that end with either "URI", "URL", "Icon", "IconColor", "IconSize", are not displayed in the table, see help for an explanation of their functionality',
              helpComponent: QueryHelp
            },
            {
              name: 'publishVariable',
              type: 'text',
              label:
                'Variabele in which item URI is published, when a link in the table is clicked.',
                description:"more information about the publish variable"
            },
            {
              name: 'highlightVariable',
              type: 'text',
              label: 'highlight table row with this uri',
            },
            {
              name: 'ashtml',
              type: 'boolean',
              label: 'tabel waardes als html',
            },
            {
              name: 'title',
              type: 'text',
              label: 'Titel boven de tabel',
            },
            {
              name: 'renderEmpty',
              type: 'boolean',
              label: 'render an empty table',
            },
            {
              name: 'zeroResult',
              type: 'text',
              label:
                'text to display when sparql results is valid but has zero results',
            },
            {
              name: 'aggregateResultsByFirstParameter',
              type: 'boolean',
              label: 'aggregate sparql results by the first parameter',
            },
            {
              name: 'hideHeader',
              type: 'boolean',
              label:
                'Hide the header',
            },
            {
              name: 'replaceHeaderJSON',
              type: 'text',
              label:
                'Key value JSON that replaces parameter headers. {"label":"naam (volledig)"}',
            },
            {
              name: 'resetSelectedValueWhenNotAvailable',
              type: 'boolean',
              label: 'reset selected value when it is not available (anymore)',
            },
            {
              name: 'hasNumberedRows',
              type: 'boolean',
              label: 'show row numbers',
            },
            {
              name: 'centerContent',
              type: 'boolean',
              label: 'center values in the tabel',
            },
            PublishUtils.getResetValueDef(),
            {
                name: 'deletebuttonrule',
                type: 'text',
                label: 'uri or tag for rule when pushing delete icon',
              },
              PublishUtils.getTimestampParametersDef(),
          ],
        }
      }
    
}

function QueryHelp() {
  return (
    <div>
      <p>When there is a query-variable-name <PreSpan>varName</PreSpan></p>
      <ul>
        <li><PreSpan>{'{varName}'}URI</PreSpan> will make it so that clicking on the table-cell value{' '}
            of <PreSpan>varName</PreSpan> will save the value of <PreSpan>{'{varName}URI'}</PreSpan> to this widget's
            {' '}configured app-variable.</li>
        <li><PreSpan>{'{varName}'}URL</PreSpan> will make it so that clicking on the table-cell-value{' '}
            of <PreSpan>varName</PreSpan> will let make the browser follow the value of{' '}
            <PreSpan>{'{varName}URI'}</PreSpan> as a URL</li>
        <li><PreSpan>{'{varName}'}Icon</PreSpan> will replace table-cell-value of <PreSpan>varName</PreSpan> with an{' '}
            icon from <a href="https://fontawesome.com/search?o=r&m=free" target="_blank" rel="noreferrer">fontawesome</a>.{' '}
            <PreSpan>{'{varName}Icon'}</PreSpan> should be formatted as <PreSpan>{'fa-{iconName}'}</PreSpan></li>
        <li><PreSpan>{'{varName}'}IconColor</PreSpan> sets the color of the aforementioned icon, which should be a{' '}
            <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value" target="_blank" rel="noreferrer">css-color-value</a></li>
        <li><PreSpan>{'{varName}'}IconSize</PreSpan> sets the height of the aforementioned icon, which should be a{' '}
            <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/height" target="_blank" rel="noreferrer">css-height-value</a></li>
      </ul>
    </div>
  )
}

function PreSpan(props) {
  const style = {
    ...props.style,
    unicodeBidi: 'embed',
    fontFamily: 'monospace',
    whiteSpace: 'pre',
  }
  return <span {...props} style={style} />
}
