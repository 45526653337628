import { lazy } from 'react';
import WidgetCategory from '../WidgetCategory';
import PublishUtils from './../../helpers/PublishUtils'



export default class ListDescriptor {

  static getDescription() {
    return {
      name: 'List',
      component:  lazy(() =>  import('./List')), 
      label: WidgetCategory.basic+' List widget',
      category:WidgetCategory.basic,
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de lijst',
        },
        {
          name: 'query',
          type: 'yasgui',
          label: 'first variabele for the labels ',
          defaultQuery:"PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n\rselect ?label ?uri where\n\r{\n\r  graph<{{graph}}>\n\r  {\n\r		?uri rdfs:label ?label\n\r  }\n\r}\n\r  \n\rlimit 10"
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'sparql parameter name for publishing select event result',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label: 'Published variabele on which to listen to to define list item selection.',
        },
        {
          name: 'multipleselect',
          type: 'boolean',
          label: 'allow multiple selections. parameters are filled as comma separated values',
        },
        {
          name: 'allMultipleSelect',
          type: 'boolean',
          label: 'Add "All" multiple select',
        },
        PublishUtils.getMultipleValueFormatOptions(),
        PublishUtils.getResetValueDef(),
        {
          name: 'customStyling',
          type: 'boolean',
          label: 'Enable Custom Styling',
        },
        {
          name: 'areaBorderStyle',
          type: 'text',
          label: 'Custom Styling: area-border-style (example: "3px solid grey"). See https://developer.mozilla.org/en-US/docs/Web/CSS/border',
        },
        {
          name: 'areaBorderRadius',
          type: 'text',
          label: 'Custom Styling: area-border-radius (examples: "0.8em", "1.2em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius',
        },
        {
          name: 'textColor',
          type: 'text',
          label: 'Custom Styling: text-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'bgColor',
          type: 'text',
          label: 'Custom Styling: background-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'selectedTextColor',
          type: 'text',
          label: 'Custom Styling: text-color of selection (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'selectedBgColor',
          type: 'text',
          label: 'Custom Styling: background-color of selection (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'itemBorderStyle',
          type: 'text',
          label: 'Custom Styling: border between each list-item (examples: "3px solid grey")'
        },
        {
          name: 'itemPaddingVertical',
          type: 'text',
          label: 'Custom Styling: vertical padding of each list-item (examples: "0.8em", "1.4em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/padding-top',
        },
        {
          name: 'itemPaddingHorizontal',
          type: 'text',
          label: 'Custom Styling: horizontal padding of each list-item (examples: "0.4em", "1.0em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/padding-left',
        },
        /*,
    {
      name: 'clearParameter',
      type: 'text',
      label: 'clears widget when parameter changes'
    }*/
      ],
    }
  }
}

  