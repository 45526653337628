export function getCgiUrl(
  cgiService: '' | 'login' | 'logout' | 'isloggedin' | 'sparql',
  repoUrl: string,
  localRepoUrl?: string
): string {
  const absoluteRepoUrl = getAbsoluteRepoUrl(repoUrl, localRepoUrl)
  return absoluteRepoUrl.replace('sparql', cgiService)
}

export function getAbsoluteRepoUrl(repoUrl: string, localRepoUrl?: string) {
  const { origin } = window.location
  const url = origin.includes('localhost') && localRepoUrl ? localRepoUrl : repoUrl

  if (url.startsWith('http://') || url.startsWith('https://')) return url
  if (url.startsWith('/')) return origin + url
  return `${origin}/${url}`
}

/**
 * If the repoURL looks like an old-style servlets-endpoint without repoName,
 * return a corrected repoURL by appending the repoName, if possible.
 */
export function fixupLegacyRepoURL(repoUrl: string | null | undefined, repoName: string | null | undefined) {
  if (!repoUrl) return repoUrl
  if (!isProbablyAWistorServletsEndpoint(repoUrl)) return repoUrl
  if (!repoName) return repoUrl
  return repoUrl.endsWith('/')
    ? repoUrl + repoName
    : repoUrl + '/' + repoName
}

function isProbablyAWistorServletsEndpoint(repoUrl: string) {
  return /servlets\/cgi\/sparql\/?$/.test(repoUrl);
}
